/* requires:
polyfill.js
*/

// accordion Toggle
const accordionActiveClass = 'is-open';
const accordionToggleCollection = document.querySelectorAll('.accordion .accordion-toggle');
for (let accordionToggle of accordionToggleCollection) {
  accordionToggle.addEventListener('click', function () {
    // const accordionWrap = this.closest('.accordion-wrap');
    const accordion = this.closest('.accordion');
    const accordionContent = this.nextElementSibling;

    // support for nested accordion
    const nestedAccordionContents = accordionContent.querySelectorAll('.accordion-content');
    let nestedAccordionContentsTotalHeight = 0;
    for (let content of nestedAccordionContents) {
      nestedAccordionContentsTotalHeight = nestedAccordionContentsTotalHeight + content.scrollHeight;
    }

    if (!accordion.classList.contains(accordionActiveClass)) {
      // const els = accordionWrap.querySelectorAll('.accordion');
      // for (let el of els) {
      //   el.classList.remove(accordionActiveClass);
      //   el.querySelector('.accordion-content').style.maxHeight = null;
      // }
      accordion.classList.toggle(accordionActiveClass);
      accordionContent.style.maxHeight = accordionContent.scrollHeight + nestedAccordionContentsTotalHeight + 50 + 'px';
    } else if (accordion.classList.contains(accordionActiveClass)) {
      accordion.classList.remove(accordionActiveClass);
      accordionContent.style.maxHeight = null;
    }
  });
}
